import React, { useState, useEffect } from "react";
import HitSendLogo from "../images/HitSend-logo.png";
import ReportAPIService from "../api/reports.api.js";
import HelperFunctions from "../HelperFunctions/HelperFunctions.js";
import FormatNumbersFunctions from "../HelperFunctions/FormatNumbersFunctions.js";

export default function ReportsListCrediflex() {
  const companyName = window.location.href.split("//").pop().split("/")[1];
  const [campaigns, setCampaigns] = useState([]);
  const [reports, setReports] = useState([]);
  const [summarisedCampaigns, setSummarisedCampaigns] = useState([]);
  const [lastModifiedDates, setLastModifiedDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getContentFromSavedFile(returnedData) {
      let tempCampaigns = [];
      let tempReports = [];
      let tempLastModifiedDates = [];
      let monthsChecked = [{ month: -1, year: -1 }];

      for (const element of returnedData) {
        const [errorContent, content] =
          await ReportAPIService.GetSavedFileContent(companyName, element);
        if (errorContent) {
          console.error("Error fetching saved content:", errorContent);
        }
        if (content[0] && content[1]) {
          tempCampaigns = tempCampaigns.concat(content[0]);
          tempReports = tempReports.concat(content[1]);
        }

        let sendTime = new Date(content[0].sendTime);
        let month = sendTime.getMonth();
        let year = sendTime.getFullYear();
        const exists = monthsChecked.some(
          (item) => item.month === month && item.year === year
        );
        if (!exists) {
          monthsChecked.push({ month, year });
          const [errorLastModifiedDate, lastModifiedDate] =
            await ReportAPIService.GetLastModifiedDate(companyName, element);
          if (errorLastModifiedDate) {
            console.error(
              "Error fetching last modified date:",
              errorLastModifiedDate
            );
          }
          if (lastModifiedDate) {
            tempLastModifiedDates =
              tempLastModifiedDates.concat(lastModifiedDate);
          }
        }
      }
      tempCampaigns.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      tempReports.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      tempLastModifiedDates.reverse();
      setCampaigns(tempCampaigns);
      setReports(tempReports);
      setLastModifiedDates(tempLastModifiedDates);

      if (tempCampaigns.length > 0) {
        let summaryByMonth = [];
        tempCampaigns.forEach((campaign) => {
          // check if we have already created the summary for this month
          let summaryDate = formatDate(new Date(campaign.sendTime));

          let foundIndex = summaryByMonth.findIndex(
            (x) => formatDate(new Date(x.sendTime)) === summaryDate
          );
          if (foundIndex === -1) {
            // if we haven't created the summary for this month, create it
            let summaryItem = {
              sendTime: campaign.sendTime,
              subject: "",
              previewText: "",
              recipients: 0,
              sent: 0,
              unsubscribed: 0,
              openRate: 0,
              views: 0,
              clicksPerUniqueOpen: 0,
              clicks: 0,
              numberOfCampaigns: 0,
            };
            summaryByMonth.push(summaryItem);
            foundIndex = summaryByMonth.length - 1;
          }
          // add the campaign data to the summary
          let report = getReportWithCampaign(tempReports, campaign.id);
          if (summaryByMonth[foundIndex].subject === "") {
            summaryByMonth[foundIndex].subject += campaign.settings.subjectLine;

            summaryByMonth[foundIndex].previewText +=
              campaign.settings.previewText;
          }

          summaryByMonth[foundIndex].recipients +=
            campaign.recipients.recipientCount;

          summaryByMonth[foundIndex].sent +=
            campaign.recipients.recipientCount -
            (report.bounces.hardBounces + report.bounces.softBounces);

          summaryByMonth[foundIndex].unsubscribed += report.unsubscribed;

          summaryByMonth[foundIndex].openRate +=
            campaign.reportSummary.openRate;

          summaryByMonth[foundIndex].views += campaign.reportSummary.opens;

          summaryByMonth[foundIndex].clicksPerUniqueOpen +=
            report.clicks.uniqueSubscriberClicks / report.opens.uniqueOpens;

          summaryByMonth[foundIndex].clicks += campaign.reportSummary.clicks;

          summaryByMonth[foundIndex].numberOfCampaigns += 1;
        });
        setSummarisedCampaigns(summaryByMonth);
      }
      setIsLoading(false);
    }

    async function getSavedFileNames() {
      const [errorReturnedData, returnedData] =
        await ReportAPIService.GetSavedFileNames(companyName);
      if (errorReturnedData) {
        console.error("Error fetching campaigns:", errorReturnedData);
      }
      if (returnedData) {
        returnedData.forEach((element, index, array) => {
          array[index] = element.split("\\").pop();
        });
      }
      return returnedData;
    }

    function formatDate(date) {
      if (companyName === "mtp") {
        return FormatNumbersFunctions.formatDate(date);
      } else {
        return FormatNumbersFunctions.formatDateNoDay(date);
      }
    }

    async function loadSavedData() {
      const returnedData = await getSavedFileNames();
      if (campaigns.length === 0) {
        setIsLoading(true);
        await getContentFromSavedFile(returnedData);
      }
    }

    loadSavedData();
  }, [companyName, campaigns, reports, lastModifiedDates.length]);

  function formatDate(date) {
    if (companyName === "mtp") {
      return FormatNumbersFunctions.formatDate(date);
    } else {
      return FormatNumbersFunctions.formatDateNoDay(date);
    }
  }

  function getReportWithCampaign(reports, campaignId) {
    for (let i = 0; i < reports.length; i++) {
      if (reports[i].id === campaignId) {
        return reports[i];
      }
    }
  }

  function backClicked() {
    window.location.href = `/`;
  }

  async function fetchLiveData() {
    await HelperFunctions.fetchLiveData(companyName, campaigns);
  }

  function showTableRows(campaign, index) {
    return (
      <tr key={index}>
        <td className="nowrap">{formatDate(new Date(campaign.sendTime))}</td>
        <td>
          <a
            href={`/${companyName}/${FormatNumbersFunctions.formatDateForFileName(
              new Date(campaign.sendTime)
            )}`}
          >
            {campaign.subject}
          </a>
          <br />
          <span style={{ fontSize: "0.9rem" }}>{campaign.previewText}</span>
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(campaign.recipients)}
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(campaign.sent)}
        </td>
        <td className="text-right">{campaign.unsubscribed}</td>
        <td className="text-right">
          {FormatNumbersFunctions.formatPercentage(
            campaign.openRate / campaign.numberOfCampaigns
          )}
          %
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(campaign.views)}
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatPercentage(
            campaign.clicksPerUniqueOpen / campaign.numberOfCampaigns
          )}
          %
        </td>
        <td className="text-right">
          {FormatNumbersFunctions.formatNumber(campaign.clicks)}
        </td>
        <td className="update-col">{lastModifiedDates[index]}</td>
      </tr>
    );
  }

  function showCampaigns() {
    if (!isLoading) {
      if (summarisedCampaigns.length > 0) {
        return summarisedCampaigns.map((campaign, index) => {
          return showTableRows(campaign, index);
        });
      } else {
        return (
          <tr>
            <td colSpan="11">No Report</td>
          </tr>
        );
      }
    }
    return (
      <tr>
        <td colSpan="11">Loading...</td>
      </tr>
    );
  }

  function main() {
    return (
      <>
        <button
          className="btn-general btn-back"
          onClick={() => {
            backClicked();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 448 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
        </button>
        <div className="App">
          <div className="container">
            <div className="reports">
              <div className="row" style={{ marginTop: "150px" }}></div>
              <div className="row">
                <h1>
                  {companyName.split("-").join(" ").toUpperCase()}
                  <br />
                  Monthly Report
                </h1>
              </div>
              <div className="row" style={{ marginTop: "50px" }}></div>
              <div className="row">
                <table className="dataTable">
                  <thead>
                    <tr>
                      <th>Sent Date</th>
                      <th>Subject</th>
                      <th>Recipients</th>
                      <th style={{ width: "5%" }}>Sent</th>
                      <th>Unsubscribes</th>
                      <th>Open Rate</th>
                      <th>Views</th>
                      <th>Clicks per Unique Open</th>
                      <th>Clicks</th>
                      <th className="update-col">Last Updated</th>
                    </tr>
                  </thead>
                  <tbody>{showCampaigns()}</tbody>
                </table>
                <button
                  className="btn-general btn-load"
                  onClick={() => {
                    fetchLiveData();
                  }}
                >
                  Load New Newsletters from Mailchimp
                </button>
              </div>
            </div>
            <img
              className="hitsend-logo-home"
              src={HitSendLogo}
              alt="HitSend Logo"
            />
          </div>
        </div>
      </>
    );
  }

  return <>{main()}</>;
}
