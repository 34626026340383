import React from "react";
import HitSendLogo from "../images/HitSend-logo.png";

export default function MonthlyPage2Cloudbase({
  campaign,
  report,
  lastCampaign,
  lastReport,
  topLinks,
}) {
  function getNameOfLink(url) {
    return url.split("utm")[0];
  }

  function main() {
    if (campaign && topLinks && report && lastCampaign && lastReport)
      return (
        <>
          <div className="row content">
            <div className="col-md-6 left-side" style={{ marginTop: "20px" }}>
              <h1
                className="heading"
                style={{
                  fontSize: "5.4vh",
                }}
              >
                OVERVIEW
              </h1>
              <table
                className="data-table"
                style={{ marginLeft: "-35px", marginTop: "10px" }}
              >
                <tbody>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.recipients.recipientCount / 1000) >
                        0
                          ? Math.floor(
                              campaign.recipients.recipientCount / 1000
                            ) +
                            "," +
                            (campaign.recipients.recipientCount % 1000 < 100
                              ? (campaign.recipients.recipientCount % 1000) %
                                  100 <
                                10
                                ? `00${
                                    campaign.recipients.recipientCount % 1000
                                  }`
                                : `0${
                                    campaign.recipients.recipientCount % 1000
                                  }`
                              : campaign.recipients.recipientCount % 1000)
                          : campaign.recipients.recipientCount}
                      </h3>
                      <h3 className="secondary-number">
                        {Math.floor(
                          lastCampaign.recipients.recipientCount / 1000
                        ) > 0
                          ? Math.floor(
                              lastCampaign.recipients.recipientCount / 1000
                            ) +
                            "," +
                            (lastCampaign.recipients.recipientCount % 1000 < 100
                              ? (lastCampaign.recipients.recipientCount %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    lastCampaign.recipients.recipientCount %
                                    1000
                                  }`
                                : `0${
                                    lastCampaign.recipients.recipientCount %
                                    1000
                                  }`
                              : lastCampaign.recipients.recipientCount % 1000)
                          : lastCampaign.recipients.recipientCount}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Recipients</h3>
                      <h5>Number of subscribed contacts in this audience</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(
                          (campaign.recipients.recipientCount -
                            (report.bounces.hardBounces +
                              report.bounces.softBounces)) /
                            1000
                        ) > 0
                          ? Math.floor(
                              (campaign.recipients.recipientCount -
                                (report.bounces.hardBounces +
                                  report.bounces.softBounces)) /
                                1000
                            ) +
                            "," +
                            ((campaign.recipients.recipientCount -
                              (report.bounces.hardBounces +
                                report.bounces.softBounces)) %
                              1000 <
                            100
                              ? ((campaign.recipients.recipientCount -
                                  (report.bounces.hardBounces +
                                    report.bounces.softBounces)) %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    (campaign.recipients.recipientCount -
                                      (report.bounces.hardBounces +
                                        report.bounces.softBounces)) %
                                    1000
                                  }`
                                : `0${
                                    (campaign.recipients.recipientCount -
                                      (report.bounces.hardBounces +
                                        report.bounces.softBounces)) %
                                    1000
                                  }`
                              : (campaign.recipients.recipientCount -
                                  (report.bounces.hardBounces +
                                    report.bounces.softBounces)) %
                                1000)
                          : campaign.recipients.recipientCount -
                            (report.bounces.hardBounces +
                              report.bounces.softBounces)}
                      </h3>
                      <h3 className="secondary-number">
                        {Math.floor(
                          (lastCampaign.recipients.recipientCount -
                            (lastReport.bounces.hardBounces +
                              lastReport.bounces.softBounces)) /
                            1000
                        ) > 0
                          ? Math.floor(
                              (lastCampaign.recipients.recipientCount -
                                (lastReport.bounces.hardBounces +
                                  lastReport.bounces.softBounces)) /
                                1000
                            ) +
                            "," +
                            ((lastCampaign.recipients.recipientCount -
                              (lastReport.bounces.hardBounces +
                                lastReport.bounces.softBounces)) %
                              1000 <
                            100
                              ? ((lastCampaign.recipients.recipientCount -
                                  (lastReport.bounces.hardBounces +
                                    lastReport.bounces.softBounces)) %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    (lastCampaign.recipients.recipientCount -
                                      (lastReport.bounces.hardBounces +
                                        lastReport.bounces.softBounces)) %
                                    1000
                                  }`
                                : `0${
                                    (lastCampaign.recipients.recipientCount -
                                      (lastReport.bounces.hardBounces +
                                        lastReport.bounces.softBounces)) %
                                    1000
                                  }`
                              : (lastCampaign.recipients.recipientCount -
                                  (lastReport.bounces.hardBounces +
                                    lastReport.bounces.softBounces)) %
                                1000)
                          : lastCampaign.recipients.recipientCount -
                            (lastReport.bounces.hardBounces +
                              lastReport.bounces.softBounces)}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Sent</h3>
                      <h5>Total number of newsletters delivered</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {report.bounces.hardBounces +
                          report.bounces.softBounces}
                      </h3>
                      <h3 className="secondary-number">
                        {lastReport.bounces.hardBounces +
                          lastReport.bounces.softBounces}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Bounced</h3>
                      <h5>
                        {report.bounces.hardBounces} hard bounces and{" "}
                        {report.bounces.softBounces} soft bounces
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">{report.unsubscribed}</h3>
                      <h3 className="secondary-number">
                        {lastReport.unsubscribed}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Unsubscribed</h3>
                      <h5>Number of contacts who have opted out</h5>
                    </td>
                  </tr>
                  <tr className="blank-row">
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.reportSummary.uniqueOpens / 1000) >
                        0
                          ? Math.floor(
                              campaign.reportSummary.uniqueOpens / 1000
                            ) +
                            "," +
                            (campaign.reportSummary.uniqueOpens % 1000 < 100
                              ? (campaign.reportSummary.uniqueOpens % 1000) %
                                  100 <
                                10
                                ? `00${
                                    campaign.reportSummary.uniqueOpens % 1000
                                  }`
                                : `0${
                                    campaign.reportSummary.uniqueOpens % 1000
                                  }`
                              : campaign.reportSummary.uniqueOpens % 1000)
                          : campaign.reportSummary.uniqueOpens}
                      </h3>
                      <h3 className="secondary-number">
                        {Math.floor(
                          lastCampaign.reportSummary.uniqueOpens / 1000
                        ) > 0
                          ? Math.floor(
                              lastCampaign.reportSummary.uniqueOpens / 1000
                            ) +
                            "," +
                            (lastCampaign.reportSummary.uniqueOpens % 1000 < 100
                              ? (lastCampaign.reportSummary.uniqueOpens %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    lastCampaign.reportSummary.uniqueOpens %
                                    1000
                                  }`
                                : `0${
                                    lastCampaign.reportSummary.uniqueOpens %
                                    1000
                                  }`
                              : lastCampaign.reportSummary.uniqueOpens % 1000)
                          : lastCampaign.reportSummary.uniqueOpens}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Opened</h3>
                      <h5>Number of people who opened newsletters</h5>
                    </td>
                  </tr>
                  <tr>
                    <td className="percentage-number">
                      <h3 className="primary-number">
                        {(
                          Math.round(
                            campaign.reportSummary.openRate * 1000
                          ).toFixed(2) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                      <h3 className="secondary-number">
                        {(
                          Math.round(
                            lastCampaign.reportSummary.openRate * 1000
                          ).toFixed(2) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Open Rate</h3>
                      <h5>Percent of sent newsletters that were opened</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.reportSummary.opens / 1000) > 0
                          ? Math.floor(campaign.reportSummary.opens / 1000) +
                            "," +
                            (campaign.reportSummary.opens % 1000 < 100
                              ? (campaign.reportSummary.opens % 1000) % 100 < 10
                                ? `00${campaign.reportSummary.opens % 1000}`
                                : `0${campaign.reportSummary.opens % 1000}`
                              : campaign.reportSummary.opens % 1000)
                          : campaign.reportSummary.opens}
                      </h3>
                      <h3 className="secondary-number">
                        {Math.floor(lastCampaign.reportSummary.opens / 1000) > 0
                          ? Math.floor(
                              lastCampaign.reportSummary.opens / 1000
                            ) +
                            "," +
                            (lastCampaign.reportSummary.opens % 1000 < 100
                              ? (lastCampaign.reportSummary.opens % 1000) %
                                  100 <
                                10
                                ? `00${lastCampaign.reportSummary.opens % 1000}`
                                : `0${lastCampaign.reportSummary.opens % 1000}`
                              : lastCampaign.reportSummary.opens % 1000)
                          : lastCampaign.reportSummary.opens}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Views</h3>
                      <h5>
                        Total number of times the newsletter has been opened
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6 right-side" style={{ padding: 0 }}>
              <table className="data-table">
                <tbody>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(
                          campaign.reportSummary.subscriberClicks / 1000
                        ) > 0
                          ? Math.floor(
                              campaign.reportSummary.subscriberClicks / 1000
                            ) +
                            "," +
                            (campaign.reportSummary.subscriberClicks % 1000 <
                            100
                              ? (campaign.reportSummary.subscriberClicks %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    campaign.reportSummary.subscriberClicks %
                                    1000
                                  }`
                                : `0${
                                    campaign.reportSummary.subscriberClicks %
                                    1000
                                  }`
                              : campaign.reportSummary.subscriberClicks % 1000)
                          : campaign.reportSummary.subscriberClicks}
                      </h3>
                      <h3 className="secondary-number">
                        {Math.floor(
                          lastCampaign.reportSummary.subscriberClicks / 1000
                        ) > 0
                          ? Math.floor(
                              lastCampaign.reportSummary.subscriberClicks / 1000
                            ) +
                            "," +
                            (lastCampaign.reportSummary.subscriberClicks %
                              1000 <
                            100
                              ? (lastCampaign.reportSummary.subscriberClicks %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    lastCampaign.reportSummary
                                      .subscriberClicks % 1000
                                  }`
                                : `0${
                                    lastCampaign.reportSummary
                                      .subscriberClicks % 1000
                                  }`
                              : lastCampaign.reportSummary.subscriberClicks %
                                1000)
                          : lastCampaign.reportSummary.subscriberClicks}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicked</h3>
                      <h5>
                        Number of people who clicked links within newsletter
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.reportSummary.clicks / 1000) > 0
                          ? Math.floor(campaign.reportSummary.clicks / 1000) +
                            "," +
                            (campaign.reportSummary.clicks % 1000 < 100
                              ? (campaign.reportSummary.clicks % 1000) % 100 <
                                10
                                ? `00${campaign.reportSummary.clicks % 1000}`
                                : `0${campaign.reportSummary.clicks % 1000}`
                              : campaign.reportSummary.clicks % 1000)
                          : campaign.reportSummary.clicks}
                      </h3>
                      <h3 className="secondary-number">
                        {Math.floor(lastCampaign.reportSummary.clicks / 1000) >
                        0
                          ? Math.floor(
                              lastCampaign.reportSummary.clicks / 1000
                            ) +
                            "," +
                            (lastCampaign.reportSummary.clicks % 1000 < 100
                              ? (lastCampaign.reportSummary.clicks % 1000) %
                                  100 <
                                10
                                ? `00${
                                    lastCampaign.reportSummary.clicks % 1000
                                  }`
                                : `0${lastCampaign.reportSummary.clicks % 1000}`
                              : lastCampaign.reportSummary.clicks % 1000)
                          : lastCampaign.reportSummary.clicks}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicks</h3>
                      <h5>
                        Total number of times links within newsletter were
                        clicked
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td className="percentage-number">
                      <h3 className="primary-number">
                        {(
                          Math.round(
                            (report.clicks.uniqueSubscriberClicks /
                              report.opens.uniqueOpens) *
                              1000
                          ).toFixed(1) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                      <h3 className="secondary-number">
                        {(
                          Math.round(
                            (lastReport.clicks.uniqueSubscriberClicks /
                              lastReport.opens.uniqueOpens) *
                              1000
                          ).toFixed(1) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicks per Unique Open</h3>
                      <h5>Percent of opened newsletters with clicks</h5>
                    </td>
                  </tr>
                  <tr className="blank-row">
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <h3
                        style={{
                          fontSize: "2.7vh",
                        }}
                      >
                        TOP LINKS
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(topLinks[0].clicks / 1000) > 0
                          ? Math.floor(topLinks[0].clicks / 1000) +
                            "," +
                            (topLinks[0].clicks % 1000 < 100
                              ? (topLinks[0].clicks % 1000) % 100 < 10
                                ? `00${topLinks[0].clicks % 1000}`
                                : `0${topLinks[0].clicks % 1000}`
                              : topLinks[0].clicks % 1000)
                          : topLinks[0].clicks}
                      </h3>
                      <h4>Clicks</h4>
                    </td>
                    <td
                      className="data-name"
                      style={{
                        display: "table-cell",
                        verticalAlign: "bottom",
                      }}
                    >
                      <p>
                        <a
                          href={topLinks[0].url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getNameOfLink(topLinks[0].url)}
                        </a>
                      </p>
                      <h5> </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(topLinks[1].clicks / 1000) > 0
                          ? Math.floor(topLinks[1].clicks / 1000) +
                            "," +
                            (topLinks[1].clicks % 1000 < 100
                              ? (topLinks[1].clicks % 1000) % 100 < 10
                                ? `00${topLinks[1].clicks % 1000}`
                                : `0${topLinks[1].clicks % 1000}`
                              : topLinks[1].clicks % 1000)
                          : topLinks[1].clicks}
                      </h3>
                      <h4>Clicks</h4>
                    </td>
                    <td
                      className="data-name"
                      style={{
                        display: "table-cell",
                        verticalAlign: "bottom",
                      }}
                    >
                      <p>
                        <a
                          href={topLinks[1].url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getNameOfLink(topLinks[1].url)}
                        </a>
                      </p>
                      <h5> </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(topLinks[2].clicks / 1000) > 0
                          ? Math.floor(topLinks[2].clicks / 1000) +
                            "," +
                            (topLinks[2].clicks % 1000 < 100
                              ? (topLinks[2].clicks % 1000) % 100 < 10
                                ? `00${topLinks[2].clicks % 1000}`
                                : `0${topLinks[2].clicks % 1000}`
                              : topLinks[2].clicks % 1000)
                          : topLinks[2].clicks}
                      </h3>
                      <h4>Clicks</h4>
                    </td>
                    <td
                      className="data-name"
                      style={{
                        display: "table-cell",
                        verticalAlign: "bottom",
                      }}
                    >
                      <p>
                        <a
                          href={topLinks[2].url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getNameOfLink(topLinks[2].url)}
                        </a>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="legend">
                <div className="legend-item">
                  <span className="legend-symbol legend-symbol-1">●</span>
                  This Month Data
                </div>
                <div className="legend-item">
                  <span className="legend-symbol legend-symbol-2">●</span>
                  Last Month Data
                </div>
              </div>
            </div>
          </div>
          <img src={HitSendLogo} alt="Hit Send Logo" className="hitsend-logo" />
        </>
      );
    else return <>Loading...</>;
  }

  return <>{main()}</>;
}
