import React, { useState } from "react";
import {
  LabelList,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

export default function YearlyPage4Special({ months, isResend }) {
  const [secondChartData, setSecondChartData] = useState([]);
  const [dataSet, setDataSet] = useState(false);

  function updateChartData() {
    setSecondChartData([
      {
        name: "",
        openRate: null,
      },
      {
        name: changeToMonthName(months[11].month),
        openRate: months[11].report
          ? (
              Math.round(
                (months[11].report.clicks.uniqueSubscriberClicks /
                  months[11].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[10].month),
        openRate: months[10].report
          ? (
              Math.round(
                (months[10].report.clicks.uniqueSubscriberClicks /
                  months[10].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[9].month),
        openRate: months[9].report
          ? (
              Math.round(
                (months[9].report.clicks.uniqueSubscriberClicks /
                  months[9].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[8].month),
        openRate: months[8].report
          ? (
              Math.round(
                (months[8].report.clicks.uniqueSubscriberClicks /
                  months[8].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[7].month),
        openRate: months[7].report
          ? (
              Math.round(
                (months[7].report.clicks.uniqueSubscriberClicks /
                  months[7].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[6].month),
        openRate: months[6].report
          ? (
              Math.round(
                (months[6].report.clicks.uniqueSubscriberClicks /
                  months[6].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[5].month),
        openRate: months[5].report
          ? (
              Math.round(
                (months[5].report.clicks.uniqueSubscriberClicks /
                  months[5].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[4].month),
        openRate: months[4].report
          ? (
              Math.round(
                (months[4].report.clicks.uniqueSubscriberClicks /
                  months[4].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[3].month),
        openRate: months[3].report
          ? (
              Math.round(
                (months[3].report.clicks.uniqueSubscriberClicks /
                  months[3].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[2].month),
        openRate: months[2].report
          ? (
              Math.round(
                (months[2].report.clicks.uniqueSubscriberClicks /
                  months[2].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[1].month),
        openRate: months[1].report
          ? (
              Math.round(
                (months[1].report.clicks.uniqueSubscriberClicks /
                  months[1].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: changeToMonthName(months[0].month),
        openRate: months[0].report
          ? (
              Math.round(
                (months[0].report.clicks.uniqueSubscriberClicks /
                  months[0].report.opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: "",
        openRate: null,
      },
    ]);
  }

  function changeToMonthName(monthNumber) {
    var month = "";
    if (monthNumber === 1) month = "January";
    else if (monthNumber === 2) month = "February";
    else if (monthNumber === 3) month = "March";
    else if (monthNumber === 4) month = "April";
    else if (monthNumber === 5) month = "May";
    else if (monthNumber === 6) month = "June";
    else if (monthNumber === 7) month = "July";
    else if (monthNumber === 8) month = "August";
    else if (monthNumber === 9) month = "September";
    else if (monthNumber === 10) month = "October";
    else if (monthNumber === 11) month = "November";
    else if (monthNumber === 12) month = "December";
    return month;
  }

  function getMinMax2() {
    const maxValue = Math.max(
      ...secondChartData.slice(1, -1).map((item) => Math.max(item.openRate))
    );

    const yMax = Math.ceil(maxValue);

    const yDomain = [0, yMax + 10];
    return yDomain;
  }

  function main() {
    if (!dataSet) {
      updateChartData();
      setDataSet(true);
    } else {
      return (
        <>
          <div className="row content">
            <h1
              className="heading"
              style={{
                fontSize: "3.5em",
                textAlign: "left",
                marginTop: "40px",
                marginLeft: "50px",
                marginBottom: "75px",
                padding: 0,
              }}
            >
              {isResend
                ? "RESEND MONTHLY COMPARISON - CLICKS"
                : "MONTHLY COMPARISON - CLICKS"}
            </h1>
            <div
              className="row"
              style={{ height: "fit-content", marginTop: "-145px" }}
            >
              <div className="col-md-12" style={{ height: "fit-content" }}>
                <div className="legend">
                  <div className="legend-item">
                    <span className="legend-symbol legend-symbol-2">●</span>
                    Clicks per Unique Open
                  </div>
                </div>
              </div>
            </div>
            <div className="row chart-container">
              <div className="col-md-12">
                <LineChart
                  width={1500}
                  height={500}
                  data={secondChartData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={getMinMax2()}
                    tickFormatter={(value) => `${value}%`}
                  />
                  <Line
                    className="chart-line"
                    name="Clicks per Unique Open"
                    type="linear"
                    dataKey="openRate"
                    strokeWidth={3}
                    dot={{ r: 5, className: "chart-dot" }}
                  >
                    <LabelList
                      className="chart-label"
                      dataKey="openRate"
                      position="top"
                      dy={-10}
                      formatter={(value) => `${value}%`}
                    />
                  </Line>
                </LineChart>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return <>{main()}</>;
}
