import React, { useState, useEffect } from "react";
import {
  LabelList,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

export default function YearlyPage3({ campaigns }) {
  const [firstChartData, setFirstChartData] = useState([]);
  const [dataSet, setDataSet] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerHeight + 550,
    height: window.innerHeight / 2,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerHeight + 550,
        height: window.innerHeight / 2,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function updateChartData() {
    setFirstChartData([
      {
        name: "",
        clicked: null,
        clicks: null,
      },
      {
        name:
          campaigns[11] && campaigns[11] !== undefined
            ? changeToMonthName(new Date(campaigns[11].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[11]
          ? campaigns[11].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[11] ? campaigns[11].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[10] && campaigns[10] !== undefined
            ? changeToMonthName(new Date(campaigns[10].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[10]
          ? campaigns[10].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[10] ? campaigns[10].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[9] && campaigns[9] !== undefined
            ? changeToMonthName(new Date(campaigns[9].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[9]
          ? campaigns[9].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[9] ? campaigns[9].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[8] && campaigns[8] !== undefined
            ? changeToMonthName(new Date(campaigns[8].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[8]
          ? campaigns[8].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[8] ? campaigns[8].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[7] && campaigns[7] !== undefined
            ? changeToMonthName(new Date(campaigns[7].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[7]
          ? campaigns[7].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[7] ? campaigns[7].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[6] && campaigns[6] !== undefined
            ? changeToMonthName(new Date(campaigns[6].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[6]
          ? campaigns[6].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[6] ? campaigns[6].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[5] && campaigns[5] !== undefined
            ? changeToMonthName(new Date(campaigns[5].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[5]
          ? campaigns[5].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[5] ? campaigns[5].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[4] && campaigns[4] !== undefined
            ? changeToMonthName(new Date(campaigns[4].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[4]
          ? campaigns[4].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[4] ? campaigns[4].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[3] && campaigns[3] !== undefined
            ? changeToMonthName(new Date(campaigns[3].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[3]
          ? campaigns[3].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[3] ? campaigns[3].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[2] && campaigns[2] !== undefined
            ? changeToMonthName(new Date(campaigns[2].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[2]
          ? campaigns[2].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[2] ? campaigns[2].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[1] && campaigns[1] !== undefined
            ? changeToMonthName(new Date(campaigns[1].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[1]
          ? campaigns[1].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[1] ? campaigns[1].reportSummary.clicks : null,
      },
      {
        name:
          campaigns[0] && campaigns[0] !== undefined
            ? changeToMonthName(new Date(campaigns[0].sendTime).getMonth() + 1)
            : "",
        clicked: campaigns[0]
          ? campaigns[0].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[0] ? campaigns[0].reportSummary.clicks : null,
      },
      {
        name: "",
        clicked: null,
        clicks: null,
      },
    ]);
  }

  function changeToMonthName(monthNumber) {
    var month = "";
    if (monthNumber === 1) month = "January";
    else if (monthNumber === 2) month = "February";
    else if (monthNumber === 3) month = "March";
    else if (monthNumber === 4) month = "April";
    else if (monthNumber === 5) month = "May";
    else if (monthNumber === 6) month = "June";
    else if (monthNumber === 7) month = "July";
    else if (monthNumber === 8) month = "August";
    else if (monthNumber === 9) month = "September";
    else if (monthNumber === 10) month = "October";
    else if (monthNumber === 11) month = "November";
    else if (monthNumber === 12) month = "December";
    return month;
  }

  function getMinMax() {
    const minValue = Math.min(
      ...firstChartData
        .slice(1, -1)
        .map((item) => Math.min(item.clicked, item.clicks))
    );
    const maxValue = Math.max(
      ...firstChartData
        .slice(1, -1)
        .map((item) => Math.max(item.clicked, item.clicks))
    );

    // Round the minimum value down to the nearest multiple of 100
    const yMin = Math.floor(minValue / 10) * 10;

    // Round the maximum value up to the nearest multiple of 100
    const yMax = Math.ceil(maxValue / 10) * 10;

    // Set the Y-axis domain
    const yDomain = [yMin - 10, yMax + 10];
    return yDomain;
  }

  function main() {
    if (!dataSet) {
      updateChartData();
      setDataSet(true);
    } else {
      return (
        <>
          <div className="row content" style={{ height: "fit-content" }}>
            <h1
              className="heading"
              style={{
                width: "fit-content",
                height: "fit-content",
                fontSize: "5.4vh",
                textAlign: "left",
                marginTop: "40px",
                marginBottom: "10vh",
                marginLeft: "40px",
                padding: 0,
              }}
            >
              MONTHLY COMPARISON - CLICKS
            </h1>
            <div className="row" style={{ height: "fit-content" }}>
              <div className="row" style={{ height: "fit-content" }}>
                <div className="col-md-12" style={{ height: "fit-content" }}>
                  <div className="legend">
                    <div className="legend-item">
                      <span className="legend-symbol legend-symbol-2">●</span>
                      Clicked Contacts
                    </div>
                    <div className="legend-item">
                      <span className="legend-symbol legend-symbol-3">●</span>
                      Newsletter Clicks
                    </div>
                  </div>
                </div>
              </div>
              <div className="row chart-container">
                <div className="col-md-12">
                  <LineChart
                    width={dimensions.width}
                    height={dimensions.height}
                    data={firstChartData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={getMinMax()} />
                    <Line
                      className="chart-line"
                      name="Clicked Contacts"
                      type="linear"
                      dataKey="clicked"
                      strokeWidth={3}
                      dot={{ r: 5, className: "chart-dot" }}
                    >
                      <LabelList
                        className="chart-label"
                        dataKey="clicked"
                        position="bottom"
                        dy={10}
                        formatter={(value) =>
                          Math.floor(value / 1000) > 0
                            ? Math.floor(value / 1000) +
                              "," +
                              (value % 1000 < 100
                                ? (value % 1000) % 100 < 10
                                  ? `00${value % 1000}`
                                  : `0${value % 1000}`
                                : value % 1000)
                            : value
                        }
                      />
                    </Line>
                    <Line
                      name="Newsletter Clicks"
                      type="linear"
                      dataKey="clicks"
                      stroke="#ea4335"
                      strokeWidth={3}
                      dot={{ r: 5, fill: "#ea4335" }}
                    >
                      <LabelList
                        dataKey="clicks"
                        position="top"
                        stroke="#ea4335"
                        dy={-10}
                        formatter={(value) =>
                          Math.floor(value / 1000) > 0
                            ? Math.floor(value / 1000) +
                              "," +
                              (value % 1000 < 100
                                ? (value % 1000) % 100 < 10
                                  ? `00${value % 1000}`
                                  : `0${value % 1000}`
                                : value % 1000)
                            : value
                        }
                      />
                    </Line>
                  </LineChart>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return <>{main()}</>;
}
