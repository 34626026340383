import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import ReportsList from "./ReportsList/ReportsList";
import ReportsListCrediflex from "./ReportsList/ReportsListCrediflex";
import ReportsListCloudbase from "./ReportsList/ReportsListCloudbase";
import MonthlyReport from "./MonthlyReportBase/MonthlyReport";
import MonthlyReportCloudbase from "./MonthlyReportCloudbase/MonthlyReportCloudbase";
import MonthlyReportCrediflex from "./MonthlyReportCrediflex/MonthlyReportCrediflex";
import YearlyReport from "./YearlyReportBase/YearlyReport";
import YearlyReportCloudbase from "./YearlyReportSpecial/YearlyReportCloudbase";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/crediflex/reports-list",
    element: <ReportsListCrediflex />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mtp/reports-list",
    element: <ReportsListCrediflex />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cloudbase-print-and-promotion/reports-list",
    element: <ReportsListCloudbase />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:companyname/reports-list",
    element: <ReportsList />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cloudbase-print-and-promotion/yearly-report",
    element: <YearlyReportCloudbase />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:companyname/yearly-report",
    element: <YearlyReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cloudbase-print-and-promotion/:date/:campaignId",
    element: <MonthlyReportCloudbase />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:companyname/:date/:campaignId",
    element: <MonthlyReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:companyname/:date",
    element: <MonthlyReportCrediflex />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
