// Format Date functions to be used in the other files
class FormatNumbersFunctions {
  formatNumber(number) {
    return Math.floor(number / 1000) > 0
      ? Math.floor(number / 1000) +
          "," +
          (number % 1000 < 100
            ? (number % 1000) % 100 < 10
              ? `00${number % 1000}`
              : `0${number % 1000}`
            : number % 1000)
      : number;
  }

  formatPercentage(number) {
    return (Math.round(number * 1000).toFixed(2) / 10).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
    );
  }

  formatDate(date) {
    var day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const shortMonth = month.substring(0, 3);
    const shortYear = year.toString().substring(2, 4);
    return `${day} ${shortMonth} ${shortYear}`;
  }

  formatDateNoDay(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const shortMonth = month.substring(0, 3);
    const shortYear = year.toString().substring(2, 4);
    return `${shortMonth} ${shortYear}`;
  }

  formatDateForFileName(date) {
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  formatDateWithTime(date) {
    var time = `${date.getHours()}-${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}-${time}`;
  }

  formatDateWithId(date, campaignId) {
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}-${campaignId}`;
  }
}
const reportAPI = new FormatNumbersFunctions();
export default reportAPI;
