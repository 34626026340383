import React, { useState } from "react";
import {
  LabelList,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

export default function YearlyPage1Special({ months, isResend }) {
  const [firstChartData, setFirstChartData] = useState([]);
  const [dataSet, setDataSet] = useState(false);

  function updateChartData() {
    setFirstChartData([
      {
        name: "",
        opened: null,
        views: null,
      },
      {
        name: changeToMonthName(months[11].month),
        opened: months[11].campaign
          ? months[11].campaign.reportSummary.uniqueOpens
          : null,
        views: months[11].campaign
          ? months[11].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[10].month),
        opened: months[10].campaign
          ? months[10].campaign.reportSummary.uniqueOpens
          : null,
        views: months[10].campaign
          ? months[10].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[9].month),
        opened: months[9].campaign
          ? months[9].campaign.reportSummary.uniqueOpens
          : null,
        views: months[9].campaign
          ? months[9].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[8].month),
        opened: months[8].campaign
          ? months[8].campaign.reportSummary.uniqueOpens
          : null,
        views: months[8].campaign
          ? months[8].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[7].month),
        opened: months[7].campaign
          ? months[7].campaign.reportSummary.uniqueOpens
          : null,
        views: months[7].campaign
          ? months[7].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[6].month),
        opened: months[6].campaign
          ? months[6].campaign.reportSummary.uniqueOpens
          : null,
        views: months[6].campaign
          ? months[6].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[5].month),
        opened: months[5].campaign
          ? months[5].campaign.reportSummary.uniqueOpens
          : null,
        views: months[5].campaign
          ? months[5].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[4].month),
        opened: months[4].campaign
          ? months[4].campaign.reportSummary.uniqueOpens
          : null,
        views: months[4].campaign
          ? months[4].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[3].month),
        opened: months[3].campaign
          ? months[3].campaign.reportSummary.uniqueOpens
          : null,
        views: months[3].campaign
          ? months[3].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[2].month),
        opened: months[2].campaign
          ? months[2].campaign.reportSummary.uniqueOpens
          : null,
        views: months[2].campaign
          ? months[2].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[1].month),
        opened: months[1].campaign
          ? months[1].campaign.reportSummary.uniqueOpens
          : null,
        views: months[1].campaign
          ? months[1].campaign.reportSummary.opens
          : null,
      },
      {
        name: changeToMonthName(months[0].month),
        opened: months[0].campaign
          ? months[0].campaign.reportSummary.uniqueOpens
          : null,
        views: months[0].campaign
          ? months[0].campaign.reportSummary.opens
          : null,
      },
      {
        name: "",
        opened: null,
        views: null,
      },
    ]);
  }

  function changeToMonthName(monthNumber) {
    var month = "";
    if (monthNumber === 1) month = "January";
    else if (monthNumber === 2) month = "February";
    else if (monthNumber === 3) month = "March";
    else if (monthNumber === 4) month = "April";
    else if (monthNumber === 5) month = "May";
    else if (monthNumber === 6) month = "June";
    else if (monthNumber === 7) month = "July";
    else if (monthNumber === 8) month = "August";
    else if (monthNumber === 9) month = "September";
    else if (monthNumber === 10) month = "October";
    else if (monthNumber === 11) month = "November";
    else if (monthNumber === 12) month = "December";
    return month;
  }

  function getMinMax() {
    const minValue = Math.min(
      ...firstChartData
        .slice(1, -1)
        .map((item) => Math.min(item.opened, item.views))
    );
    const maxValue = Math.max(
      ...firstChartData
        .slice(1, -1)
        .map((item) => Math.max(item.opened, item.views))
    );

    // Round the minimum value down to the nearest multiple of 100
    const yMin = Math.floor(minValue / 100) * 100;

    // Round the maximum value up to the nearest multiple of 100
    const yMax = Math.ceil(maxValue / 100) * 100;

    // Set the Y-axis domain
    const yDomain = [yMin - 100, yMax + 100];
    return yDomain;
  }

  function main() {
    if (!dataSet) {
      updateChartData();
      setDataSet(true);
    } else {
      return (
        <>
          <div className="row content">
            <h1
              className="heading"
              style={{
                fontSize: "3.5em",
                textAlign: "left",
                marginTop: "40px",
                marginLeft: "50px",
                marginBottom: "75px",
                padding: 0,
              }}
            >
              {isResend
                ? "RESEND MONTHLY COMPARISON - OPENS"
                : "MONTHLY COMPARISON - OPENS"}
            </h1>
            <div
              className="row"
              style={{ height: "fit-content", marginTop: "-145px" }}
            >
              <div className="col-md-12" style={{ height: "fit-content" }}>
                <div className="legend">
                  <div className="legend-item">
                    <span className="legend-symbol legend-symbol-2">●</span>
                    Newsletter Opened
                  </div>
                  <div className="legend-item">
                    <span className="legend-symbol legend-symbol-3">●</span>
                    Newsletter Views
                  </div>
                </div>
              </div>
            </div>
            <div className="row chart-container">
              <div className="col-md-12">
                <LineChart
                  width={1500}
                  height={500}
                  data={firstChartData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={getMinMax()} />
                  <Line
                    className="chart-line"
                    name="Newsletter Opened"
                    type="linear"
                    dataKey="opened"
                    strokeWidth={3}
                    dot={{ r: 5, className: "chart-dot" }}
                  >
                    <LabelList
                      className="chart-label"
                      dataKey="opened"
                      position="bottom"
                      dy={10}
                      formatter={(value) =>
                        Math.floor(value / 1000) > 0
                          ? Math.floor(value / 1000) +
                            "," +
                            (value % 1000 < 100
                              ? (value % 1000) % 100 < 10
                                ? `00${value % 1000}`
                                : `0${value % 1000}`
                              : value % 1000)
                          : value
                      }
                    />
                  </Line>
                  <Line
                    className="newsletter-views"
                    name="Newsletter Views"
                    type="linear"
                    dataKey="views"
                    stroke="#ea4335"
                    strokeWidth={3}
                    dot={{ r: 5, fill: "#ea4335" }}
                  >
                    <LabelList
                      dataKey="views"
                      position="top"
                      stroke="#ea4335"
                      dy={-10}
                      formatter={(value) =>
                        Math.floor(value / 1000) > 0
                          ? Math.floor(value / 1000) +
                            "," +
                            (value % 1000 < 100
                              ? (value % 1000) % 100 < 10
                                ? `00${value % 1000}`
                                : `0${value % 1000}`
                              : value % 1000)
                          : value
                      }
                    />
                  </Line>
                </LineChart>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return <>{main()}</>;
}
