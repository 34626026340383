import React, { useState, useEffect } from "react";
import {
  LabelList,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

export default function YearlyPage4({ campaigns, reports }) {
  const [secondChartData, setSecondChartData] = useState([]);
  const [dataSet, setDataSet] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerHeight + 550,
    height: window.innerHeight / 2,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerHeight + 550,
        height: window.innerHeight / 2,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function updateChartData() {
    setSecondChartData([
      {
        name: "",
        openRate: null,
      },
      {
        name:
          campaigns[11] && campaigns[11] !== undefined
            ? changeToMonthName(new Date(campaigns[11].sendTime).getMonth() + 1)
            : "",
        openRate: reports[11]
          ? (
              Math.round(
                (reports[11].clicks.uniqueSubscriberClicks /
                  reports[11].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[10] && campaigns[10] !== undefined
            ? changeToMonthName(new Date(campaigns[10].sendTime).getMonth() + 1)
            : "",
        openRate: reports[10]
          ? (
              Math.round(
                (reports[10].clicks.uniqueSubscriberClicks /
                  reports[10].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[9] && campaigns[9] !== undefined
            ? changeToMonthName(new Date(campaigns[9].sendTime).getMonth() + 1)
            : "",
        openRate: reports[9]
          ? (
              Math.round(
                (reports[9].clicks.uniqueSubscriberClicks /
                  reports[9].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[8] && campaigns[8] !== undefined
            ? changeToMonthName(new Date(campaigns[8].sendTime).getMonth() + 1)
            : "",
        openRate: reports[8]
          ? (
              Math.round(
                (reports[8].clicks.uniqueSubscriberClicks /
                  reports[8].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[7] && campaigns[7] !== undefined
            ? changeToMonthName(new Date(campaigns[7].sendTime).getMonth() + 1)
            : "",
        openRate: reports[7]
          ? (
              Math.round(
                (reports[7].clicks.uniqueSubscriberClicks /
                  reports[7].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[6] && campaigns[6] !== undefined
            ? changeToMonthName(new Date(campaigns[6].sendTime).getMonth() + 1)
            : "",
        openRate: reports[6]
          ? (
              Math.round(
                (reports[6].clicks.uniqueSubscriberClicks /
                  reports[6].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[5] && campaigns[5] !== undefined
            ? changeToMonthName(new Date(campaigns[5].sendTime).getMonth() + 1)
            : "",
        openRate: reports[5]
          ? (
              Math.round(
                (reports[5].clicks.uniqueSubscriberClicks /
                  reports[5].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[4] && campaigns[4] !== undefined
            ? changeToMonthName(new Date(campaigns[4].sendTime).getMonth() + 1)
            : "",
        openRate: reports[4]
          ? (
              Math.round(
                (reports[4].clicks.uniqueSubscriberClicks /
                  reports[4].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[3] && campaigns[3] !== undefined
            ? changeToMonthName(new Date(campaigns[3].sendTime).getMonth() + 1)
            : "",
        openRate: reports[3]
          ? (
              Math.round(
                (reports[3].clicks.uniqueSubscriberClicks /
                  reports[3].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[2] && campaigns[2] !== undefined
            ? changeToMonthName(new Date(campaigns[2].sendTime).getMonth() + 1)
            : "",
        openRate: reports[2]
          ? (
              Math.round(
                (reports[2].clicks.uniqueSubscriberClicks /
                  reports[2].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[1] && campaigns[1] !== undefined
            ? changeToMonthName(new Date(campaigns[1].sendTime).getMonth() + 1)
            : "",
        openRate: reports[1]
          ? (
              Math.round(
                (reports[1].clicks.uniqueSubscriberClicks /
                  reports[1].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name:
          campaigns[0] && campaigns[0] !== undefined
            ? changeToMonthName(new Date(campaigns[0].sendTime).getMonth() + 1)
            : "",
        openRate: reports[0]
          ? (
              Math.round(
                (reports[0].clicks.uniqueSubscriberClicks /
                  reports[0].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: "",
        openRate: null,
      },
    ]);
  }

  function changeToMonthName(monthNumber) {
    var month = "";
    if (monthNumber === 1) month = "January";
    else if (monthNumber === 2) month = "February";
    else if (monthNumber === 3) month = "March";
    else if (monthNumber === 4) month = "April";
    else if (monthNumber === 5) month = "May";
    else if (monthNumber === 6) month = "June";
    else if (monthNumber === 7) month = "July";
    else if (monthNumber === 8) month = "August";
    else if (monthNumber === 9) month = "September";
    else if (monthNumber === 10) month = "October";
    else if (monthNumber === 11) month = "November";
    else if (monthNumber === 12) month = "December";
    return month;
  }

  function getMinMax2() {
    const maxValue = Math.max(
      ...secondChartData.slice(1, -1).map((item) => Math.max(item.openRate))
    );

    const yMax = Math.ceil(maxValue);

    const yDomain = [0, yMax + 10];
    return yDomain;
  }

  function main() {
    if (!dataSet) {
      updateChartData();
      setDataSet(true);
    } else {
      return (
        <>
          <div className="row content" style={{ height: "fit-content" }}>
            <h1
              className="heading"
              style={{
                width: "fit-content",
                height: "fit-content",
                fontSize: "5.4vh",
                textAlign: "left",
                marginTop: "40px",
                marginBottom: "10vh",
                marginLeft: "40px",
                padding: 0,
              }}
            >
              MONTHLY COMPARISON - CLICKS
            </h1>
            <div className="row" style={{ height: "fit-content" }}>
              <div className="row" style={{ height: "fit-content" }}>
                <div className="col-md-12" style={{ height: "fit-content" }}>
                  <div className="legend">
                    <div className="legend-item">
                      <span className="legend-symbol legend-symbol-2">●</span>
                      Clicks per Unique Open
                    </div>
                  </div>
                </div>
              </div>
              <div className="row chart-container">
                <div className="col-md-12">
                  <LineChart
                    width={dimensions.width}
                    height={dimensions.height}
                    data={secondChartData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      domain={getMinMax2()}
                      tickFormatter={(value) => `${value}%`}
                    />
                    <Line
                      className="chart-line"
                      name="Clicks per Unique Open"
                      type="linear"
                      dataKey="openRate"
                      strokeWidth={3}
                      dot={{ r: 5, className: "chart-dot" }}
                    >
                      <LabelList
                        className="chart-label"
                        dataKey="openRate"
                        position="top"
                        dy={-10}
                        formatter={(value) => `${value}%`}
                      />
                    </Line>
                  </LineChart>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return <>{main()}</>;
}
