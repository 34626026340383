//wrapper for all api calls
class ReportAPIService {
  async DoGetJson(url) {
    try {
      var response = await fetch(url, {
        headers: { "Content-type": "application/json" },
      });
      var jsonresult = await response.json();
      return [null, jsonresult];
    } catch (error) {
      return [error];
    }
  }

  async DoGetText(url) {
    try {
      var response = await fetch(url, {
        headers: { "Content-type": "application/json" },
      });
      var jsonresult = await response.text();
      return [null, jsonresult];
    } catch (error) {
      return [error];
    }
  }

  async DoPostJSON(url, data) {
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: JSON.stringify(data) }),
      });
      return [null, "Data saved successfully"];
    } catch (error) {
      return [error];
    }
  }

  async GetCampaigns(companyName) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/${companyName}`;
    return await this.DoGetJson(fullurl);
  }

  async GetCampaignWithId(companyName, campaignId) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/${companyName}/${campaignId}`;
    return await this.DoGetJson(fullurl);
  }

  async GetCampaignsByMonthYear(companyName, month, year) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/month/${companyName}/${month}/${year}`;
    return await this.DoGetJson(fullurl);
  }

  async GetReports(companyName) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/reports/${companyName}`;
    return await this.DoGetJson(fullurl);
  }

  async GetReportsByMonthYear(companyName, month, year) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/month/reports/${companyName}/${month}/${year}`;
    return await this.DoGetJson(fullurl);
  }

  async GetReportWithId(companyName, campaignId) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/reports/${companyName}/${campaignId}`;
    return await this.DoGetJson(fullurl);
  }

  async GetLinksWithId(companyName, campaignId) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/top-clicks/${companyName}/${campaignId}`;
    return await this.DoGetJson(fullurl);
  }

  async GetLinksByMonthYear(companyName, month, year) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/month/top-clicks/${companyName}/${month}/${year}`;
    return await this.DoGetJson(fullurl);
  }

  async GetPreviewWithId(companyName, campaignId) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/html/${companyName}/${campaignId}`;
    return await this.DoGetText(fullurl);
  }

  async GetPreviewByMonthYear(companyName, month, year) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/month/html/${companyName}/${month}/${year}`;
    return await this.DoGetJson(fullurl);
  }

  async GetSavedFileNames(companyName) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/saved/${companyName}`;
    return await this.DoGetJson(fullurl);
  }

  async GetSavedFileContent(companyName, fileName) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/saved/${companyName}/${fileName}`;
    return await this.DoGetJson(fullurl);
  }

  async GetLastModifiedDate(companyName, fileName) {
    const fullurl = `https://hitsendreporting.s05.system7.co.nz/api/api/report/saved/${companyName}/${fileName}/date`;
    return await this.DoGetText(fullurl);
  }

  async SaveData(fullurl, data) {
    return await this.DoPostJSON(fullurl, data);
  }
}
const reportAPI = new ReportAPIService();
export default reportAPI;
